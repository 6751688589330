import axios from '@axios'
import store from './../../store'
import firebase from './../../@core/firebase/firebase'

export default {
  namespaced: true,
  state: {
    companies: [],
    regions: [],
    counties: []
  },
  getters: {},
  mutations: {
    SET_COMPANIES (state, data) {
      state.companies = data
    },
    ADD_COMPANY (state, data) {
      state.companies.unshift(data)
    },
    EDIT_COMPANY (state, item) {
      const index = state.companies.findIndex((i) => i._id === item._id)
      if (index >= 0) {
        Object.assign(state.companies[index], item)
      }
    },
    DELETE_COMPANY (state, id) {
      const index = state.companies.findIndex((i) => i._id === id)
      if (index >= 0) {
        state.companies.splice(index, 1)
      }
    },
    SET_REGIONS (state, data) {
      state.regions = data
    },
    SET_COUNTIES (state, data) {
      state.counties = data
    }
  },
  actions: {

    getCompanies ({commit}) {
      return new Promise(((resolve, reject) => {
        axios.get('companies')
          .then((response) => {
            commit('SET_COMPANIES', response.data.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    getViewerCompanies ({commit}) {
      return new Promise(((resolve, reject) => {
        axios.get('viewer-companies')
          .then((response) => {
            commit('SET_COMPANIES', response.data.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    addCompany ({commit}, data) {
      return new Promise(((resolve, reject) => {
        const userType = atob(store.state.user_type)
        const action = (userType === 'ADMIN') ? 'companies' : 'viewer-companies'
        axios.post(action, data)
          .then((response) => {
            commit('ADD_COMPANY', response.data.item)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    addLicenseFile({commit}, data) {
      return new Promise((resolve, reject) => {
        if (data.licenseFile) {
          const storageRef = firebase.storage().ref();
          const fileName = data.licenseFile.name
          const audioRef = storageRef.child(fileName);
          audioRef.put(data.licenseFile)
            .then(data => {
              const file = `https://firebasestorage.googleapis.com/v0/b/${data.metadata.bucket}/o/${fileName}?alt=media`
              resolve({
                status: true,
                file
              })
            })
            .catch(error => {
              reject({
                status: false,
                message: 'Սխալ',
                error
              })
            })
        }
        else {
          reject({
            status: false,
            message: 'Սխալ'
          })
        }
      })
    },

    editCompany ({commit}, data) {
      return new Promise(((resolve, reject) => {
        const userType = atob(store.state.user_type)
        const action = (userType === 'ADMIN') ? 'companies' : 'viewer-companies'
        axios.put(action + '/' + data._id, data)
          .then((response) => {
            commit('EDIT_COMPANY', response.data.item)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    editCompanyContract ({commit}, {id, form}) {
      return new Promise(((resolve, reject) => {
        const userType = atob(store.state.user_type)
        const action = (userType === 'ADMIN') ? 'companies' : 'viewer-companies'
        axios.put('companies-contract/' + id, form, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    deleteCompany ({commit}, id) {
      return new Promise(((resolve, reject) => {
        axios.delete('companies/' + id)
          .then((response) => {
            commit('DELETE_COMPANY', id)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    editCompanyData ({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.put(`companies_owner/${payload.id}`, payload)
          .then(response => {
            commit("EDIT_COMPANY", response.data.item)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getRegions ({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('locations/regions')
          .then(response => {
            commit("SET_REGIONS", response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getCounties ({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('locations/counties')
          .then(response => {
            commit("SET_COUNTIES", response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  },
}
