<template>
  <b-card-code v-if="$checkPermission($options.name, 'view')" id="companies" no-body>

    <div class="page-header">
      <b-row class="pageContent">
        <b-col class="filtersContainer" md="12">
          <div class="filters">

            <div class="filter-column">
              <b-button @click="add" v-b-modal.modal-form variant="primary">Ավելացնել</b-button>
            </div>

            <div class="filter-column">
              <select @change="changeRegion" class="selectFilter" v-model="filters.region">
                <option value="">Ընտրեք մարզը</option>
                <option v-for="region in filter_regions" :value="region.id">
                  {{ region.name }}
                </option>
              </select>
            </div>

            <div class="filter-column">
              <select @change="changeCounty" class="selectFilter" v-model="filters.county">
                <option value="">Ընտրեք համայնքը</option>
                <option v-for="county in filter_counties" :value="county.id">
                  {{ county.name }}
                </option>
              </select>
            </div>

            <div class="filter-column">
              <div class="stationsCount">{{items.length}} կայան</div>
            </div>

          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
        striped
        hover
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
    >
      <template #cell(address)="data">
        {{ addressName(data.item) }}
      </template>

      <template #cell(index)="data">
        {{data.index + 1}}
      </template>

      <template #cell(status)="data">
        <fa-icon v-if="$checkPermission($options.name, 'update')" @click="addWarning(data.item)"
                 icon="exclamation-triangle"
                 :class="'table-action-icon item-status status-' + data.item.warned"/>
        <fa-icon v-if="$checkPermission($options.name, 'update')" @click="banCompany(data.item)" icon="ban"
                 :class="'table-action-icon item-status status-' + data.item.blocked"/>
      </template>

      <template #cell(actions)="data">
        <fa-icon v-if="$checkPermission($options.name, 'update')" @click="edit(data.item)" icon="edit"
                 class="table-action-icon accent"/>
      </template>

      <template #cell(company_details)="data">
        <fa-icon :class="company_details_icon(data.item)" icon="check-circle"/>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
          :label="'Գտնվել է ' + totalRows + ' տող'"
          label-cols="3"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
      >
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
        >
          <template #prev-text>
            <feather-icon
                icon="ChevronLeftIcon"
                size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
                icon="ChevronRightIcon"
                size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>

    <!-- modal login-->
    <b-modal
        id="modal-form"
        :no-close-on-backdrop="true"
        cancel-variant="outline-secondary"
        :ok-title="modal_title"
        cancel-title="Կասեցնել"
        centered
        size="lg"
        :title="modal_title"
        :is-valid="false"
        @ok="confirmForm"
        :ok-disabled="validateForm"
    >


      <b-form>

        <b-row>

          <b-col lg="6">
            <div class="column-title">Կազմակերպություն</div>

            <b-form-group>
              <b-form-input
                  v-model="form.name"
                  id="name"
                  type="text"
                  placeholder="Կազմակերպության անվանում"
              />
            </b-form-group>
            <b-form-group>
              <b-form-input
                  v-model="form.boss"
                  id="boss"
                  type="text"
                  placeholder="Տնօրեն"
              />
            </b-form-group>
            <b-form-group>
              <b-form-input
                  v-model="form.license"
                  id="license"
                  type="text"
                  placeholder="Լիցենզիա"
              />
            </b-form-group>

            <b-form-group>
              <b-form-input
                  v-model="form.phone"
                  id="phoneNumber"
                  type="text"
                  placeholder="Հեռախոսահամար"
              />
            </b-form-group>

            <b-form-group>
              <div class="fileInput">
                <input type="file" @change="selectLicenseFile"/>
                <fa-icon icon="paperclip" />
                <span class="label">Կցել լիցենզիան</span>
                <fa-icon v-if="this.form.licenseFile" icon="check" class="selected"/>
              </div>
            </b-form-group>

          </b-col>

          <b-col lg="6">

            <div class="column-title">Հասցե</div>

            <b-form-group>
              <v-select
                  @input="changeFormRegion"
                  placeholder="Մարզ"
                  id="vue-select-region"
                  :reduce="prefix => prefix.id"
                  v-model="locationData.region"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="regions"
              />
            </b-form-group>

            <b-form-group v-if="locationData.region !== null">
              <v-select
                  placeholder="Համայնք"
                  id="vue-select-county"
                  :reduce="prefix => prefix.id"
                  v-model="locationData.county"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="counties"
              />
            </b-form-group>

            <b-form-group v-if="locationData.county !== '' && locationData.county !== null">
              <b-form-input
                  placeholder="Փողոց"
                  id="vue-select-street"
                  v-model="locationData.street"
              />
            </b-form-group>

            <b-form-group v-if="locationData.street !== '' && locationData.street !== null">
              <b-form-input
                  placeholder="Փողոց"
                  id="vue-select-address"
                  v-model="locationData.address"
              />
            </b-form-group>

          </b-col>
        </b-row>

      </b-form>
    </b-modal>

    <b-modal
        id="modal-warning"
        :no-close-on-backdrop="true"
        cancel-variant="outline-secondary"
        ok-title="Զգուշացնել"
        cancel-title="Կասեցնել"
        centered
        size="sm"
        title="Զգուշացում"
        :is-valid="false"
        @ok="confirmWarning"
        :ok-disabled="validateWarningForm"
    >
      <b-form>
        <b-form-group>
          <b-form-textarea
              v-model="warningText"
              id="name"
              type="text"
              placeholder="Ծանուցում"
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal
        id="modal-ban"
        :no-close-on-backdrop="true"
        cancel-variant="outline-secondary"
        ok-title="Բլոկավորել"
        cancel-title="Կասեցնել"
        centered
        size="sm"
        title="Բլոկավորում"
        :is-valid="false"
        @ok="confirmBan"
        :ok-disabled="validateBanForm"
    >
      <b-form>
        <b-form-group>
          <b-form-textarea
              v-model="banText"
              id="ban-name"
              type="text"
              placeholder="Ծանուցում"
          />
        </b-form-group>
      </b-form>
    </b-modal>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import companyModule from '@/store/companies/companyModule'
import locations from '@/store/locations/locations'
import vSelect from 'vue-select'
import { yandexMap, ymapMarker } from 'vue-yandex-maps'

import {
  BAvatar,
  BBadge,
  BButton,
  BCardBody,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTable,
  BFormTextarea,
  BFormCheckbox,
} from 'bootstrap-vue'
import store from '@/store'

export default {
  name: 'view-companies',
  components: {
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BForm,
    BRow,
    BCol,
    BFormTextarea,
    vSelect,
    yandexMap,
    ymapMarker,
    BFormCheckbox
  },
  data() {
    return {
      COMPANIES_STORE_MODULE_NAME: 'companiesModule',
      LOCATIONS_STORE_MODULE_NAME: 'locationsModule',
      perPage: 20,
      pageOptions: [this.perPage],
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filters: {
        region: '',
        county: ''
      },
      filter_regions: [],
      filter_counties: [],
      filterOn: [],
      warningText: '',
      banText: '',
      locationData: {
        region: null,
        county: null,
        street: '',
        address: ''
      },
      fields: [
        {
          key: 'index',
          label: 'Հ/Հ'
        },
        {
          key: 'name',
          label: 'Անվանում',
        },
        {
          key: 'address',
          label: 'Հասցե',
        },
        {
          key: 'license',
          label: 'Լիցենզիայի համարը'
        }
      ],
      addForm: false,
      editForm: false,
      formFields: {
        name: '',
        address: null,
        reqNo: '',
        PRReqNo: '',
        boss: '',
        tax: '',
        taxID: '',
        standCount: '',
        license: '',
        longitude: '',
        latitude: '',
        phone: '',
        bank: '',
        station_1: false,
        station_2: false,
        licenseFile: null
      },
      form: {},
      selectedItem: null,
      coords: [40.1535005, 44.4185275],
      companyCoords: [],
      items: [],
      licenseFile: null,
    }
  },
  computed: {

    company_details_icon() {
      return (company) => {
        let status = (company.tax && company.bank && company.tax && company.boss) ? 'true' : 'false'
        return `table-action-icon item-status status-${status}`
      }
    },

    companies() {
      return this.$store.state[this.COMPANIES_STORE_MODULE_NAME].companies
    },

    all_addresses() {
      return this.$store.state[this.LOCATIONS_STORE_MODULE_NAME].all_addresses
    },

    validateWarningForm() {
      return this.warningText === ''
    },

    validateBanForm() {
      return this.banText === ''
    },

    addresses() {
      return this.$store.state[this.COMPANIES_STORE_MODULE_NAME].addresses.map(e => ({
        ...e,
        label: e.name,
        id: e._id
      }))
    },

    counties() {
      return this.$store.state[this.COMPANIES_STORE_MODULE_NAME].counties.map(e => ({
        label: e.name,
        id: e.id, ...e
      }))
    },

    all_counties() {
      return this.$store.state[this.COMPANIES_STORE_MODULE_NAME].all_counties
    },

    all_streets() {
      return this.$store.state[this.COMPANIES_STORE_MODULE_NAME].all_streets
    },

    streets() {
      return this.$store.state[this.COMPANIES_STORE_MODULE_NAME].streets.map(e => ({
        ...e,
        label: e.name,
        id: e._id,
      }))
    },

    regions() {
      return this.$store.state[this.COMPANIES_STORE_MODULE_NAME].regions.map(e => ({
        label: e.name,
        id: e.id, ...e
      }))
    },

    all_regions() {
      return this.$store.state[this.COMPANIES_STORE_MODULE_NAME].regions
    },

    totalRows() {
      return this.items.length
    },

    modal_title() {
      return (this.addForm) ? 'Ավելացնել' : 'Խմբագրել'
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
          .filter(f => f.sortable)
          .map(f => ({
            text: f.label,
            value: f.key
          }))
    },

    validateForm() {
      return this.form.name === '' || this.form.boss === '' || this.locationData.region === null || this.locationData.county === null || this.locationData.street === null || this.locationData.street === '' || this.locationData.address === null || this.locationData.address === ''
    },

    addressName() {
      return (item) => {
        return item.region + ' ' + item.county + ' ' + item.street + ' ' + item.address
      }
    }
  },
  mounted() {
    // Set the initial number of items

  },
  async created() {
    if (!store.hasModule(this.COMPANIES_STORE_MODULE_NAME)) store.registerModule(this.COMPANIES_STORE_MODULE_NAME, companyModule)
    if (!store.hasModule(this.LOCATIONS_STORE_MODULE_NAME)) store.registerModule(this.LOCATIONS_STORE_MODULE_NAME, locations)
    this.getCompanies()
    this.getRegions()
    this.getCounties()
  },
  methods: {

    getCompanies() {
      this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/getCompanies')
          .then(() => {
            this.items = this.companies
          })
    },
    getRegions() {
      this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/getRegions')
          .then(response => {
            this.filter_regions = response.data.data
          })
    },
    getCounties() {
      this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/getCounties')
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    add() {
      this.editForm = false
      this.addForm = true
      this.form = this.$store.getters['getForm'](true)(this.formFields)(null)
      this.companyCoords = this.coords
    },
    edit(item) {
      let regionItem
      let countyItem
      let regionIndex = this.regions.findIndex(r => r.name === item.region)
      if (regionIndex >= 0) {
        this.locationData.region = this.regions[regionIndex].id
        regionItem = this.regions[regionIndex]
      }

      let countyIndex = this.counties.findIndex(c => c.name === item.county)
      if (countyIndex >= 0) {
        this.locationData.county = this.counties[countyIndex].id
        countyItem = this.counties[countyIndex]
      }

      this.locationData.street = item.street
      this.locationData.address = item.address
      this.addForm = false
      this.editForm = true
      this.form = this.$store.getters['getForm'](false)(this.formFields)(item)
      if (this.form.latitude && this.form.longitude) {
        this.companyCoords = [this.form.latitude, this.form.longitude]
      } else {
        if (countyItem && countyItem.latitude && countyItem.longitude) {
          this.companyCoords = [countyItem.latitude, countyItem.longitude]
        } else {
          this.companyCoords = [regionItem.latitude, regionItem.longitude]
        }
      }
      this.$bvModal.show('modal-form')
    },
    deleteItem(item) {
      this.$swal({
        title: 'Հաստատե՞լ գործողությունը',
        text: 'Դուք ցանկանում եք հեռացնել կազմակերպությունը',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Հաստատել',
        cancelButtonText: 'Մերժել',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/deleteCompany', item.id)
                  .then(() => {
                    this.$swal({
                      icon: 'success',
                      title: 'Հեռացվել է!',
                      text: 'Կազմակերպությունը հաջողությամբ հեռացվեց',
                      customClass: {
                        confirmButton: 'btn btn-success',
                      },
                    })
                  })
                  .catch((error) => {
                    this.$root.$emit('getError', error)
                  })
            }
          })
    },
    changeStatus(item) {
      const status = !item.status
      const action = (status) ? 'ակտիվացնել' : 'պասիվացնել'
      this.$swal({
        title: 'Հաստատե՞լ գործողությունը',
        text: 'Դուք ցանկանում եք ' + action + ' կազմակերպությունը',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Հաստատել',
        cancelButtonText: 'Մերժել',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              item.status = status
              this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/editCompany', item)
                  .then(() => {
                    this.$swal({
                      icon: 'success',
                      title: '',
                      text: 'Փոփոխությունները պահպանվեցին',
                      customClass: {
                        confirmButton: 'btn btn-success',
                      },
                    })
                  })
                  .catch((error) => {
                    this.$root.$emit('getError', error)
                  })
            }
          })
    },
    confirmForm() {
      if (this.addForm) {
        let regionName = ''
        let countyName = ''
        const regionIndex = this.regions.findIndex((r) => r.id === this.locationData.region)
        if (regionIndex >= 0) {
          regionName = this.regions[regionIndex].label
        }
        const countyIndex = this.counties.findIndex((c) => c.id === this.locationData.county)
        if (countyIndex >= 0) {
          countyName = this.counties[countyIndex].label
        }
        this.form.region = regionName
        this.form.county = countyName
        this.form.street = this.locationData.street
        this.form.address = this.locationData.address

        this.form.latitude = this.companyCoords[0]
        this.form.longitude = this.companyCoords[1]
        this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/addCompany', this.form)
            .then(() => {
              this.locationData = {
                region: null,
                county: null,
                street: '',
                address: '',
              }
            })
            .catch((error) => {
              this.$root.$emit('getError', error)
            })
      } else {
        let regionName = ''
        let countyName = ''
        const regionIndex = this.regions.findIndex((r) => r.id === this.locationData.region)
        if (regionIndex >= 0) {
          regionName = this.regions[regionIndex].label
        }
        const countyIndex = this.counties.findIndex((c) => c.id === this.locationData.county)
        if (countyIndex >= 0) {
          countyName = this.counties[countyIndex].label
        }
        this.form.region = regionName
        this.form.county = countyName
        this.form.street = this.locationData.street
        this.form.address = this.locationData.address

        this.form.latitude = this.companyCoords[0]
        this.form.longitude = this.companyCoords[1]

        this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/editCompany', this.form)
            .then(() => {
              this.locationData = {
                region: null,
                county: null,
                street: '',
                address: '',
              }
            })
            .catch((error) => {
              this.$root.$emit('getError', error)
            })
      }
    },
    changeFormRegion(regionId) {
      this.locationData.county = null
      this.locationData.street = null
      this.form.address = null
      this.$store.commit(this.LOCATIONS_STORE_MODULE_NAME + '/FILTER_COUNTIES_BY_REGION_ID', regionId)
    },
    changeFormCounty(countyId) {
      this.locationData.street = null
      this.form.address = null
      this.$store.dispatch(this.LOCATIONS_STORE_MODULE_NAME + '/getStreets', 'county=' + countyId)
    },
    changeFormStreet(streetId) {
      this.form.address = null
      this.$store.dispatch(this.LOCATIONS_STORE_MODULE_NAME + '/getAddresses', 'street=' + streetId)
    },
    addWarning(item) {
      if (item.warned) {
        this.$swal({
          title: 'Հաստատե՞լ գործողությունը',
          text: 'Դուք ցանկանում եք պասիցացնել զգուշացումը',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Հաստատել',
          cancelButtonText: 'Կասեցնել',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
            .then(result => {
              if (result.value) {
                const payload = {
                  _id: item._id,
                  warned: false,
                  warning: '',
                  warmDate: null
                }
                this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/editCompany', payload)
                    .then(() => {
                      this.warningText = ''
                    })
              }
            })
      } else {
        this.selectedItem = item
        this.$bvModal.show('modal-warning')
      }
    },
    banCompany(item) {
      if (item.blocked) {
        this.$swal({
          title: 'Հաստատե՞լ գործողությունը',
          text: 'Դուք ցանկանում եք պասիցացնել բլոկավորումը',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Հաստատել',
          cancelButtonText: 'Կասեցնել',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
            .then(result => {
              if (result.value) {
                const payload = {
                  _id: item._id,
                  blocked: false,
                  blockComment: ''
                }
                this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/editCompany', payload)
                    .then(() => {
                      this.banText = ''
                    })
              }
            })
      } else {
        this.selectedItem = item
        this.$bvModal.show('modal-ban')
      }
    },
    confirmWarning() {
      const payload = {
        _id: this.selectedItem._id,
        warned: true,
        warning: this.warningText,
        warmDate: new Date()
      }
      this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/editCompany', payload)
          .then(() => {
            this.warningText = ''
            this.selectedItem = null
          })
    },
    confirmBan() {
      const payload = {
        _id: this.selectedItem._id,
        blocked: true,
        blockComment: this.banText
      }
      this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/editCompany', payload)
          .then(() => {
            this.banText = ''
            this.selectedItem = null
          })
    },

    changeMarkerPosition(e) {
      this.companyCoords = e.get('coords')
    },

    changeRegion() {
      this.filter_counties = []
      this.filter_counties = this.counties.filter(c => c.region === this.filters.region)
      this.items = this.companies
      this.filters.county = ''
      let regionIndex = this.regions.findIndex(r => r.id === this.filters.region)
      if (regionIndex > -1) {
        let region = this.regions[regionIndex]
        this.items = this.companies.filter(c => c.region === region.name)
      }
    },

    changeCounty() {
      this.items = []
      let regionIndex = this.regions.findIndex(r => r.id === this.filters.region)
      if (regionIndex > -1) {
        let region = this.regions[regionIndex]
        if (!this.filters.county) {
          this.items = this.companies.filter(c => c.region === region.name)
        } else {
          let countyIndex = this.counties.findIndex(c => c.id === this.filters.county)
          if (countyIndex > -1) {
            let county = this.counties[countyIndex]
            this.items = this.companies.filter(c => c.region === region.name && c.county === county.name)
          }
        }
      }
    },

    selectLicenseFile (event) {
      this.form.licenseFile = null
      this.licenseFile = null
      if (event.target.files.length > 0) {
        this.licenseFile = event.target.files[0]
        let payload = {
          licenseFile: this.licenseFile
        }
        this.$store.dispatch(`${this.COMPANIES_STORE_MODULE_NAME}/addLicenseFile`, payload)
        .then((response => {
          this.form.licenseFile = response.file
        }))
        .catch(error => {
          this.form.licenseFile = null
        })
      }
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#companies {
  .table-responsive {
    max-height: calc(var(--vh, 1vh) * 100 - 16rem);
    min-height: calc(var(--vh, 1vh) * 100 - 16rem);
  }
}

.column-title {
  font-size: 1rem;
  margin: 8px 0;
  border-bottom: 1px solid #eee;
  font-weight: bold;
  padding-bottom: 3px;
}

#map {
  width: 100%;
  height: 300px;

  .company-map {
    width: 100%;
    height: 100%;
  }
}

.selectFilter {
  height: 100%;
  border: 1px solid #ccc;
  padding: 0 15px;
}
.filter-column {
  display: flex;
  align-items: center;
}
.stationsCount {
  margin-left: 10px;
  font-weight: bold;
  color: #000;
  font-size: 15px;
}
.fileInput {
  display: flex;
  align-items: center;
  input {
    position: absolute;
    opacity: 0;
  }
  svg {
    color: #000;
    font-size: 20px;
    &.selected {
      color: #4caf50;
      margin-left: 19px;
      font-size: 16px;
    }
  }
  .label {
    color: #000;
    margin-left: 15px;
  }
}
</style>
